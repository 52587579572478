.media {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 5px;
  margin: 5px 0;
  background-color: #2f173a;
  border-radius: 10px;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.media:hover {
  background-color: #753e8f;
}

@media (max-width: 550px) {
  .media {
    width: 46%;
  }
}

.poster {
  border-radius: 10px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 17px;
  padding: 8px 0;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  padding: 0 2px;
  padding-bottom: 3px;
}
